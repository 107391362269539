import { CssBaseline } from "@material-ui/core"
import CustomThemeProvider from "./CustomThemeProvider"
import Router from "./Router"
import React from 'react'
import ApiProvider from "../api/ApiProvider"
import { LocalizationProvider } from "@mui/lab"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/tr';

const App = () => {

  return (
    <CustomThemeProvider>
      <ApiProvider>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router />
        </LocalizationProvider>
      </ApiProvider>
    </CustomThemeProvider>
  )
}

export default App
