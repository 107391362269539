import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import SuspenseFallback from './SuspenseFallback';
import AdminRouter from './AdminRouter'
import CompanyRouter from './CompanyRouter';


const login = lazy(() => import('../pages/login'))

const adminIndex = lazy(() => import('../pages/admin/index'))
const adminCompanies = lazy(() => import('../pages/admin/companies'))
const adminVehicles = lazy(() => import('../pages/admin/vehicles'))
const adminSchedulers = lazy(() => import('../pages/admin/schedulers'))
const adminReports = lazy(() => import('../pages/admin/reports'))
const adminScore = lazy(() => import('../pages/admin/score'))
const workingHours = lazy(() => import('../pages/admin/workingHours'))
const lines = lazy(() => import('../pages/admin/lines'))
const routes = lazy(() => import('../pages/admin/routes'))

const companyIndex = lazy(() => import('../pages/company/index'))


const Router = () => {

    return (
        <BrowserRouter>
            <Suspense fallback={<SuspenseFallback />}>
                <Switch>
                    <Route exact path="/" component={login} />

                    <AdminRouter exact path="/admin" component={adminIndex} />
                    <AdminRouter exact path="/admin/companies" component={adminCompanies} />
                    <AdminRouter exact path="/admin/vehicles" component={adminVehicles} />
                    <AdminRouter exact path="/admin/schedulers" component={adminSchedulers} />
                    <AdminRouter exact path="/admin/reports" component={adminReports} />
                    <AdminRouter exact path="/admin/score" component={adminScore} />
                    <AdminRouter exact path="/admin/working-hours" component={workingHours} />
                    <AdminRouter exact path="/admin/lines" component={lines} />
                    <AdminRouter exact path="/admin/routes" component={routes} />

                    <CompanyRouter exact path="/company" component={companyIndex} />

                    <Route component={login} />
                    <AdminRouter component={adminIndex} />
                    <CompanyRouter component={companyIndex} />

                </Switch>
            </Suspense>
        </BrowserRouter>
    )

}

export default Router

