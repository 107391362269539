import { useState, useEffect } from "react"
import { Redirect, Route } from "react-router-dom"
import { getMeRequest } from "../api/admin-controllers/account-controller"
import CompanyLayout from "./CompanyLayout"
import SuspenseFallback from "./SuspenseFallback"



const CustomerRouter = ({ component: Component, ...rest }) => {


    const [AUTH_STATUS, setAUTH_STATUS] = useState("WAITING")



    const checkAuthentication = async () => {
        // setAUTH_STATUS("SUCCESS")
        // return
        try {
            let res = await getMeRequest()
            if (res?.data) {
                //console.log(res?.data)
                setAUTH_STATUS("SUCCESS")
            }
        } catch (error) {
            //console.log("auth hatası", error)
            if (error?.response?.status === 401) {
                //console.log("LOGİN DEĞİLSİN")
                setAUTH_STATUS("FAILED")
            }
        }


    }

    useEffect(() => {
        (
            async () => {
                await checkAuthentication()
            }
        )()
        return () => {

        }
    }, [])


    return (
        <Route  {...rest}>
            {
                AUTH_STATUS === "WAITING" ? <SuspenseFallback />
                    : AUTH_STATUS === "FAILED" ? <Redirect to={{ pathname: "/login" }} />
                        : AUTH_STATUS === "SUCCESS" ? <CompanyLayout> <Component /></CompanyLayout>
                            : <div>Error</div>
            }
        </Route>

    )
}

export default CustomerRouter